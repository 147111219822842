.floating {
  position: fixed;
  bottom: 10%;
  right: 10%;
}

.floatingUpload {
  position: relative;
  bottom: 2em;
}

.floatingMkdir {
  position: relative;
  right: 5em;
}

.fileUpload {
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  margin-left: 1em;
  color: #002b36;
  background-color: #769fb6;
}

.input {
  display: none;
}

.textInput {
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
}

.submit {
  border: none;
  color: #002b36;
  background-color: #d4cb92;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  margin-left: 1em;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ff6b6c;
  color: #073642;
  border-radius: 3px;
  margin-top: 1em;
}
