.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: auto;
  border-radius: 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.modalContent {
  padding: 1em;
  background-color: #073642;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  background-color: #002b36;
  padding: 1em;
}
