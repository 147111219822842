.form {
  max-width: 240px;
}

.container {
  display: flex;
  justify-content: center;
}

.input {
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
  margin-bottom: 2em;
}

.userIcon {
  color: #073642;
  margin-left: -2em;
  margin-top: 8px;
}

.keyIcon {
  color: #073642;
  margin-left: -2em;
  margin-top: 14px;
}

.submit {
  border: none;
  color: #002b36;
  background-color: #d4cb92;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ff6b6c;
  color: #073642;
  border-radius: 3px;
}
