:root {
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color-scheme: light dark;
  color: #e6e1c5;
  background: #073642;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: #fff;
    background: #1c1c1c;
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#app > main {
  display: flex;
  padding-top: 8rem;
  margin: auto;
  max-width: 720px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 639px) {
  #app > main {
    margin: 0 2rem;
    max-width: 100%;
  }
}

.table {
  width: 100%;
  text-align: left;
}

a {
  color: #d4cb92;
}

table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
}

table > thead {
  color: #80a4ed;
}

td {
  word-wrap: break-word;
  vertical-align: baseline;
}

th:first-of-type {
  width: 2em;
}

td:first-of-type {
  width: 2em;
}

th:nth-of-type(3) {
  width: 8em;
}

td:nth-of-type(3) {
  width: 8em;
}

th:last-of-type {
  width: 8em;
}

th:last-of-type {
  width: 8em;
}

@media (max-width: 639px) {
  th:last-of-type {
    width: 4em;
  }

  th:last-of-type {
    width: 4em;
  }
}
